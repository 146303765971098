import React from 'react'
import { Link } from 'react-router-dom'

import ROUTES from '../../../constants/routes'
import { linkIcon } from '../../../constants/icons'

import { SaleSearch, TrackingSearch } from './TicketsLinks'

const ChatDetail = ({ ticket, showDetails }) => (
  <div>
    {showDetails && (
      <div className="flex flex-col gap-1 py-2 px-3 text-sm text-white">
        <p>
          ID:{' '}
          <Link to={`${ROUTES.TICKETS}/${ticket.id}`} className="inline-flex underline">
            {ticket.id}
            <img
              src={linkIcon}
              alt="link to ticket"
              className="ml-1 w-3 pt-1 brightness-0 invert"
            />
          </Link>
        </p>
        <p>Motivo: {ticket.type}</p>
        <p>Detalle: {ticket.description || '-'}</p>
        <p>Holding: {ticket.holdingName || '-'}</p>
        {ticket.package.orderId && (
          <div className="flex items-center gap-1">
            OV:{' '}
            <SaleSearch searchValue={ticket.package.orderId} customClass="brightness-0 invert" />
          </div>
        )}
        {ticket.package.trackingNumber && (
          <div className="flex items-center gap-1">
            OT:{' '}
            <TrackingSearch
              courier={ticket.package.courier}
              trackingId={ticket.package.trackingNumber}
              customClass="brightness-0 invert"
            />
          </div>
        )}
        {ticket.package.courier && <p>Courier: {ticket.package.courier}</p>}
        {ticket.package.packageId && (
          <div className="flex items-center gap-1">
            PackageID:{' '}
            <SaleSearch searchValue={ticket.package.packageId} customClass="brightness-0 invert" />
          </div>
        )}
      </div>
    )}
  </div>
)

export default ChatDetail
