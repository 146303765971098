import React, { useState } from 'react'

import useLocalStorage from '../../../hooks/useLocalStorage'
import { getFormattedDate } from '../../../utils/dates'
import { STATUS_COLOR, STATUS_ICONS } from '../../../constants/tickets'
import { TICKET_ICONS } from '../../../constants/icons'
import ROUTES from '../../../constants/routes'
import Table from '../../shared/Table/Table'
import TableRow from '../../shared/Table/TableRow'
import Button from '../../shared/Button'
import ChatContainer from '../ManageTicket/ChatContainer'

const TicketsTable = ({ tickets, handleSort, isLoading, error }) => {
  const [ticketId, setTicketId] = useState('')
  const [openChat, setOpenChat] = useState(false)
  const [openTicketId, setOpenTicketId] = useLocalStorage('openTicketId', null)

  const openChatHandler = (id) => {
    setTicketId(id)
    setOpenChat(true)
    setOpenTicketId(id)
  }

  if (!isLoading && !error && tickets.length === 0) {
    return (
      <div className="mx-10 border-x border-b bg-white py-8 text-center text-s">
        No hay tickets con este criterio
      </div>
    )
  }

  const sortValues = {
    'O. de venta': { key: 'orderId', type: 'string' },
    Estado: { key: 'status', type: 'string' },
    ID: { key: 'id', type: 'number' },
    Fecha: { key: 'createdAt', type: 'date' },
    Holding: { key: 'holdingName', type: 'string' },
    Motivo: { key: 'type', type: 'string' },
    Courier: { key: 'courier', type: 'string' },
    Actualización: { key: 'updatedAt', type: 'date' }
  }

  return (
    <>
      <Table
        maxHeight="max-h-[66vh]"
        sortValues={sortValues}
        sortData={handleSort}
        headers={[
          'O. de venta',
          'Estado',
          'ID',
          'Fecha',
          'Holding',
          'Motivo',
          'Courier',
          'Actualización',
          'Chat'
        ]}
        isLoading={isLoading}
        error={error}
      >
        {tickets?.map((ticket) => (
          <TableRow
            key={ticket.id}
            items={[
              ticket.orderId,
              <div className={`flex w-max gap-2 rounded py-1 px-2 ${STATUS_COLOR[ticket.status]}`}>
                <img
                  src={STATUS_ICONS[ticket.status]}
                  alt={ticket.status}
                  className="my-auto h-auto w-2.5"
                />
                {ticket.status}
              </div>,
              ticket.id,
              getFormattedDate(ticket.createdAt),
              ticket.holdingName,
              ticket.type.split('/')[1].trim(),
              ticket.courier || '-',
              getFormattedDate(ticket.updatedAt),
              <Button
                color="bg-pinflag"
                small
                onClick={() => {openChatHandler(ticket.id)
                }}
              >
                <img src={TICKET_ICONS.chat} alt="chat" className="h-6 py-1" />
              </Button>
            ]}
            rowId={ticket.id}
            route={ROUTES.TICKETS}
            openRow={setOpenTicketId}
            isSelected={openTicketId === ticket.id}
          />
        ))}
      </Table>
     {openChat && (
      <ChatContainer ticketId={ticketId} openChat={openChat} setOpenChat={setOpenChat} />)}
    </>
  )
}

export default TicketsTable
