import React, { useState, useEffect, useCallback } from 'react'

import useLocalStorage from '../../hooks/useLocalStorage'
import useFetch from '../../hooks/useFetch'
import { getTickets } from '../../helpers/requests/tickets'
import { isSubstring } from '../../utils/strings'
import { sortArrayByKey } from '../../utils/array'
import { COURIERS } from '../../constants/couriers'
import { TICKETS_STATUS, TICKET_STATES_INDEX } from '../../constants/tickets'
import Pagination from '../../components/shared/Pagination'
import SectionHeader from '../../components/shared/SectionHeader'
import SearchBar from '../../components/shared/SearchBar'
import ActionsBar from '../../components/shared/Table/ActionsBar'
import TicketsTable from '../../components/Tickets/AllTickets/TicketsTable'
import TicketsFilters from '../../components/Tickets/AllTickets/TicketsFilters'
import Notifications from '../../components/Tickets/ManageTicket/Notifications'

const AllTickets = () => {
  const [tickets, setTickets] = useState([])
  const [searchBarInput, setSearchBarInput] = useLocalStorage('searchBarInput', '')
  const [selectedFilters, setSelectedFilters] = useLocalStorage('selectedFilters', [])
  const [page, setPage] = useLocalStorage('page', 1)
  const [pageSize, setPageSize] = useLocalStorage('pageSize', 10)
  const [searchResult, setSearchResult] = useState([])
  const [selectedType, setSelectedType] = useLocalStorage('selectedType', '')
  const [selectedCourier, setSelectedCourier] = useLocalStorage('selectedCourier', '')

  const setTicketsData = useCallback((data) => {
    const flattenTickets = data.map((ticket) => {
      const item = { ...ticket, ...ticket.package }
      delete item.package
      return item
    })
    flattenTickets.sort((ticketA, ticketB) => ticketB.id - ticketA.id)

    setTickets(flattenTickets)
    setSearchResult(flattenTickets)
  }, [])

  const { isLoading, error } = useFetch(getTickets, setTicketsData)

  useEffect(() => {
    if (searchBarInput !== '') {
      const filteredTickets = tickets.filter(
        (ticket) =>
          isSubstring(ticket.id.toString(), searchBarInput) ||
          (ticket.pinflagId && isSubstring(ticket.pinflagId, searchBarInput)) ||
          (ticket.courier && isSubstring(ticket.courier, searchBarInput)) ||
          (ticket.orderId && isSubstring(ticket.orderId, searchBarInput)) ||
          (ticket.customerName && isSubstring(ticket.customerName, searchBarInput)) ||
          (ticket.holdingName && isSubstring(ticket.holdingName, searchBarInput)) ||
          (ticket.trackingNumber && isSubstring(ticket.trackingNumber, searchBarInput))
      )
      setSearchResult(filteredTickets)
    } else {
      setSearchResult(tickets)
    }
  }, [searchBarInput, tickets])

  const searchHandler = (searchTerm) => {
    setSearchBarInput(searchTerm)
  }

  const handleSort = (sortValues, order) => {
    const sortedCurrentTickets = sortArrayByKey(searchResult, sortValues, order)

    setSearchResult(sortedCurrentTickets)
    const sortedTickets = sortArrayByKey(tickets, sortValues, order)

    setTickets(sortedTickets)
  }

  const stateFilteredTickets = searchResult
    .filter((ticket) =>
      selectedFilters.length > 0
        ? selectedFilters.includes(TICKET_STATES_INDEX[ticket.status])
        : true
    )
    .filter((ticket) => (selectedType ? ticket.type === selectedType : true))
    .filter((ticket) => {
      if (selectedCourier === 'Otros Couriers') {
        return ticket.courier && !COURIERS.includes(ticket.courier)
      }
      if (selectedCourier === 'Sin Courier') {
        return !ticket.courier
      }
      return selectedCourier ? ticket.courier === selectedCourier : true
    })

  const currentTickets = stateFilteredTickets.slice(pageSize * (page - 1), pageSize * page)

  return (
    <div className="flex h-screen flex-col bg-light-grey">
    <SectionHeader
        title="Solicitudes"
        searchBar={
          <SearchBar
            searchbarInput={searchBarInput}
            searchKeyword={searchHandler}
            setSearchbarInput={(search) => {
              setSearchBarInput(search)
            }}
          />
        }
      />
      <Notifications tickets={tickets} />
      <ActionsBar
        statusFilters={TICKETS_STATUS}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        rightChildren={
          <TicketsFilters
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            selectedCourier={selectedCourier}
            setSelectedCourier={setSelectedCourier}
          />}
      />
      <TicketsTable
        tickets={currentTickets}
        handleSort={handleSort}
        isLoading={isLoading}
        error={error}
      />
      <div className="mx-10 flex h-24 place-content-end">
        {stateFilteredTickets.length > 0 && (
          <Pagination
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            itemsCount={stateFilteredTickets.length}
          />
        )}
      </div>
    </div>
  )
}

export default AllTickets
